<template>
  <div class="boxC">
    <el-row class="dash-board">
    <el-button type="primary" v-permission="['Check:select']" @click="attendSel()" class="inquire">查询</el-button>
    <el-button type="warning" class="reset" @click="reset">重置</el-button>
    <el-button type="primary" class="Print" @click="Print">生成考勤数据</el-button>
    <p class="name" style="font-size: 12px" >打卡日期:</p>
    <p class="one" style="font-size: 12px">状态:</p>
    <p class="studen"  style="font-size: 12px">学员:</p>
    <p class="iopen" style="font-size: 12px">课程内容:</p>
    <p class="two" style="font-size: 12px">培训班名字:</p>
    <el-date-picker v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期" class="time"
                    @change="searchChange" clearable>
    </el-date-picker>
    <el-select v-model="attStatus" class="in2"><!--状态-->
      <div v-for="(i,k) in qingjiaList" :key="k">
        <el-option :key="k" :value="i.sort" :label="i.value">{{i.value}}</el-option>
      </div>
    </el-select>
    <el-input v-model="content" class="in3"><!--课程-->
      <el-option lable="" value=""></el-option>
    </el-input>
    <!--班级-->
    <el-select v-model="className" class="in4">
        <el-option v-for="(i,index) in dataList2" :key="index" :label="i.className" :value="i.classId"></el-option>
    </el-select>
    <el-input v-model="stuName" class="in1"></el-input><!--学员-->
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData">
        <el-table-column
          label="姓名"
          align="center"
          width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="培训班名称"
          align="center"
          width="190">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="课程主题"
          align="center"
          width="190">
          <template slot-scope="scope">
            <span>{{ scope.row.trainContent }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="课程内容"
          align="center"
          width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上课教室"
          align="center"
          width="90">
          <template slot-scope="scope">
            <span>{{ scope.row.classroom }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上课时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.schoolTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="下课时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.quitTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="打卡时间"
                        align="center"
                        width="84">
          <template slot-scope="scope">
            <span v-if="scope.row.clockTime && (scope.row.attStatus == 1 || scope.row.attStatus == 2)">{{ scope.row.clockTime }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="80">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.attStatus == 2">迟到</el-tag>
            <el-tag type="warning" v-if="scope.row.attStatus == 3">缺勤</el-tag>
            <el-tag type="success" v-if="scope.row.attStatus == 4">请假</el-tag>
            <el-tag v-if="scope.row.attStatus == 1">正常上课</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          >
          <template slot-scope="scope">
            <el-button size="mini" type="success" v-permission="['Check:edit']" @click="updata(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
      :title="titlee" :visible.sync="updatayyds">
      <el-form :model="up">
        <el-col :span="12">
        <el-form-item label="班级名称" required >
          <el-input  style="width: 200px" v-model="up.className" :disabled="true"></el-input>
        </el-form-item>
          <el-form-item label="打卡时间" required v-if="up.attStatus ==1 || up.attStatus == 2">
            <!-- <el-input  style="width: 200px" v-model="up.clockTime"></el-input> -->
            <el-date-picker
                  v-model="up.clockTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
          </el-form-item>
          <el-form-item label="打卡时间" required v-else>
            <el-input  style="width: 200px" v-model="up.clockTime" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
        <el-form-item label="状态" required >
          <el-select @change="qingjiaChange" v-model="up.attStatus">
            <!-- <el-option v-for="(i, k) in dictionaryList1" :key="k" :value="i.attStatus" :label="i.value"></el-option> -->
          <!-- <div v-for="(i,k) in qingjiaList" :key="k">
            <el-option :key="k" :value="i.sort" :label="i.value">{{i.value}}</el-option>
          </div> -->
          <el-option v-for="(i,k) in qingjiaList" :key="k" :value="i.sort" :label="i.value">{{i.value}}</el-option>
        </el-select>
        </el-form-item> <el-form-item label="姓名" required >
          <el-input  style="width: 200px" v-model="up.stuName"></el-input>
        </el-form-item>
        </el-col>
      </el-form>
      <div  class="dialog-footer">
        <el-button @click="updatayyds = false">取 消</el-button>
        <el-button type="primary" @click="appupdata()">提交</el-button>
      </div>
    </el-dialog>
    <!-- 打印考勤 -->
    <el-dialog
      title="生成考勤数据" :visible.sync="PrintVisible" width="45%">
      <el-form :model="PrintForm">
          <el-form-item label="班级" label-width="80px">
            <el-select v-model="PrintForm.className" @change="classChange" style="width:90%">
                <el-option v-for="(i,index) in dataList2" :key="index" :label="i.className" :value="i.classId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程" label-width="80px">
            <el-select v-model="PrintForm.curriculum" style="width:90%">
                <el-option v-for="(i,index) in curriculumData" :key="index" :label="i.content" :value="i.id"></el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PrintCancel">取 消</el-button>
        <el-button type="primary" v-if="PrintForm.curriculum" @click="PrintCurriculum()">提 交</el-button>
      </div>
    </el-dialog>
     <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { attendSel, GetAllById, detailAttendance, updAttendance } from '@/api/admin.js'
  import { getAllClassByMoHu, curriculumList, PrintCurriculum } from '@/api/user.js'
  export default {
    name: 'Leavefer',
    data () {
      return {
        dataList2: [],
        qingjiaName: '',
        up: {
          attStatus: '',
          className: '',
          clockTime: '',
          stuName: '',
          tt: '',
          date: ''
        },
        updatayyds: false,
        titlee: '修改信息',
        className: '',
        trainContent: '',
        content: '',
        stuName: '',
        selectTime: '',
        startTime: '',
        endTime: '',
        attStatus: '',
        qingjiaList: [],
        tableData: [{
          attendanceId: '',
          schoolTime: '',
          quitTime: '',
          clockTime: '',
          className: '',
          trainContent: '',
          stuName: '',
          startTime: '',
          endTime: '',
          attStatus: ''
        }],
        form: {},
        input: '',
        title: '填写请假信息',
        reapplyfor: false,
        formLabelWidth: '100px',
        dictionaryList1: [
          { attStatus: 1, value: '正常上课' },
          { attStatus: 2, value: '迟到' },
          { attStatus: 3, value: '缺勤' },
          { attStatus: 4, value: '请假' }
        ],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        PrintVisible: false,
        PrintForm: {
          className: '',
          curriculum: ''
        },
        curriculumData: []
      }
    },
    mounted () {
      this.attendSel()
      this.GetAllById(190)
      this.getAllClassByMoHu()
    },
    methods: {
      // 生成考勤按钮点击
      Print() {
        this.PrintVisible = true
      },
      // 选择班级后获取课程
      classChange() {
        this.PrintForm.curriculum = ''
        const query = {
          trainId: this.PrintForm.className
        }
        curriculumList(query).then(resp => {
            if (resp.data.code === '0') {
                this.curriculumData = resp.data.data.rows
             }
          })
      },
      // 生成考勤记录取消
      PrintCancel() {
          this.PrintVisible = false
          this.PrintForm = {}
      },
      // 生成考勤记录提交
      PrintCurriculum() {
        const query = {
          trainId: this.PrintForm.className,
          courseId: this.PrintForm.curriculum
        }
        PrintCurriculum(query).then(resp => {
          if (resp.data.code === '0') {
              this.PrintVisible = false
              this.PrintForm = {}
               this.$notify({
                message: '操作成功',
                type: 'success'
               })
             } else {
                this.$notify({
                  message: resp.data.msg,
                  type: 'error'
                })
              }
        })
      },
      getAllClassByMoHu: function () {
        getAllClassByMoHu(this.listQuery).then(resp => {
          if (resp.data.code === '0') {
            this.dataList2 = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      appupdata() {
        updAttendance(this.courseId, this.stuId, this.up.stuName, this.up.attStatus, this.up.clockTime).then(resp => {
          if (resp.data.code === '200') {
            this.$notify({
              message: '修改成功',
              type: 'success'
            })
            this.updatayyds = false
            this.attendSel()
          } else {
            this.$notify({
              title: '操作失败',
              message: resp.data.msg,
              type: 'error'
            })
          }
        })
      },
      updata(row) {
        this.updatayyds = true
        this.courseId = row.courseId
        this.stuId = row.stuId
        detailAttendance(row.courseId, row.stuId).then(resp => {
          if (resp.data.code === '0') {
            this.up = resp.data.data.rows[0] // 接收返回的数据
            this.qingjiaName = this.up.attStatus + ''
          }
        })
      },
      GetAllById (id) { // 字典
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
            this.qingjiaList = resp.data.data
          }
        })
      },
      reset() {
        this.className = ''
        this.content = ''
        this.stuName = ''
        this.selectTime = ''
        this.attStatus = ''
        this.attendSel()
      },
      // 选择请假列表 值变化
      qingjiaChange (val) {
        for (let i = 0; i < this.up.attStatus.length; i++) {
          if (this.up.attStatus[i].value === val) {
            this.up.attStatus = this.up.attStatus[i].sort
          }
        }
      },
      attendSel () {
        if (!this.selectTime) {
          this.selectTime = []
          this.startTime = this.selectTime[0]
          this.endTime = this.selectTime[1]
        } else {
          this.startTime = this.selectTime[0]
          this.endTime = this.selectTime[1]
        }
        const query = {
          classId: this.className,
          content: this.content,
          stuName: this.stuName,
          startTime: this.startTime,
          endTime: this.endTime,
          attStatus: this.attStatus,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        console.log(query)
        attendSel(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.attendSel()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.attendSel()
      },
      searchChange (e) {
        console.log(e)
        if (!e) {
          e = []
          this.selectTime = []
          this.startTime = ''
          this.endTime = ''
          this.attendSel()
        } else {
          console.log(e)
        }
      }
    }
  }
</script>

<style scoped>
  .boxC{margin-top: -10px}
  .dash-board {
    display: flex;
    /*left: 10px;*/
    background-color: white;
    height: 120px;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 70px;
    left:700px;
  }
  .reset{
    position: absolute;
    top: 70px;
    left: 755px;
  }
  .Print{
    position: absolute;
    top: 70px;
    left: 825px;
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 540px;
  }
  .in2{
    width: 150px;
    position: absolute;
    top: 20px;
    left: 540px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 70px;
  }
  .in4{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 320px;
  }
  .name{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 10px;
  }
  .one{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 500px;
  }
  .iopen{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 10px;
  }
  .two{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 250px;
  }
  .time{
    width: 398px;
    font-size: 15px;
    position: absolute;
    top: 20px;
    left: 70px;
  }
  .studen{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 500px;
  }
</style>
